<!-- <app-web-navbar></app-web-navbar> -->
<div class="main-container">
  <!-- Hero Section -->
  <section class="hero">
    <div class="hero-content">
      <h1><span class="light-text">Transform Your Business  with </span> A-Square ERP Software</h1>
      <p>Streamline operations, boost productivity &amp; drive growth with our all in one ERP software</p>
      <button [routerLink]="['/subscriber-signup']" class="primary-button">Get a Free Demo</button>
    </div>
    <div class="hero-image">
      <img src="../../../../../assets/images/enquiry/businessman.webp" alt="Mockup displaying A-Square ERP software on a laptop, tablet, and smartphone, illustrating its versatility and user-friendly interface.">
    </div>
  </section>

  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': isPopupFormOpen ? 'block' : 'none'}">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <section class="enquiry-form-container">
            <form [formGroup]="enquiryform" (ngSubmit)="onSubmit()" class="enquiry-form">
              <div class="close-modal-button">
                <i class="fas fa-times" (click)="closeModal()"></i>
              </div>
              <h2>Request a Free Demo</h2>
              <div class="form-group">
                <input formControlName="FullName" type="text" placeholder="Full Name*">
                <div *ngIf="enquiryform.get('FullName')?.invalid && enquiryform.get('FullName')?.touched" class="error-message">
                  Name is required
                </div>
              </div>

              <div class="form-group">
                <input formControlName="MobileNumber" type="tel" placeholder="Phone Number*" (blur)="verifyMobileNumber()">
                <div *ngIf="enquiryform.get('MobileNumber')?.invalid && enquiryform.get('MobileNumber')?.touched" class="error-message">
                  Valid phone number is required
                </div>
              </div>
              <div class="form-group" *ngIf="isOTPSent && !isOTPVerified">
                <input type="number" name="" id="" formControlName="OTP" >
                <div *ngIf="!isOTPVerified && !isOTPValid" class="error-message">
                  Invalid OTP!
                </div>
              </div>
              <div class="form-group">
                <input formControlName="Email" type="email" placeholder="Email Address">
              </div>
              <div class="form-group">
                <input formControlName="Company" type="text" placeholder="Company Name">
              </div>
              <button type="button" (click)="sendOTP()" *ngIf="!isOTPSent && isMobileVerified">Send OTP</button>
              <button type="button" (click)="verifyOTP()" *ngIf="isOTPSent && !isOTPVerified">Verify OTP</button>
              <button type="submit" class="primary-button" [disabled]="!isOTPVerified || enquiryform.invalid">Request Demo</button>
            </form>

            <div class="img-container">
              <img src="" alt="">
            </div>

          </section>
        </div>
      </div>
    </div>
  </div>



  <!-- Enquiry Form -->
  <section class="enquiry-form-container" #enquiryForm>
    <form [formGroup]="enquiryform" (ngSubmit)="onSubmit()" class="enquiry-form" id="enquiry-form">
      <h2>Request a Free Demo</h2>
      <div class="form-group">
        <input formControlName="FullName" type="text" placeholder="Full Name*">
        <div *ngIf="enquiryform.get('FullName')?.invalid && enquiryform.get('FullName')?.touched" class="error-message">
          Name is required
        </div>
      </div>

      <div class="form-group">
        <input formControlName="MobileNumber" type="tel" placeholder="Phone Number*" (blur)="verifyMobileNumber()">
        <div *ngIf="enquiryform.get('MobileNumber')?.invalid && enquiryform.get('MobileNumber')?.touched" class="error-message">
          Valid phone number is required
        </div>
      </div>
      <div class="form-group" *ngIf="isOTPSent && !isOTPVerified">
        <input type="number" name="" id="" formControlName="OTP" placeholder="OTP*">
        <div *ngIf="isOTPValid == false" class="error-message">
          Invalid OTP!
        </div>
      </div>
      <div class="form-group">
        <input formControlName="Email" type="email" placeholder="Email Address">
      </div>
      <div class="form-group">
        <input formControlName="Company" type="text" placeholder="Company Name">
      </div>
      <button type="button" (click)="sendOTP()" *ngIf="!isOTPSent && isMobileVerified">Send OTP</button>
      <button type="button" (click)="verifyOTP()" *ngIf="isOTPSent && !isOTPVerified">Verify OTP</button>
      <button type="submit" class="primary-button" [disabled]="!isOTPVerified || enquiryform.invalid">Request Demo</button>
    </form>

    <div class="slider-container">
      <div class="slider-heading">
        <p>Tailored ERP for efficiency.</p>
        <h2>Industries We Serve</h2>
      </div>
      <!-- Swiper Section -->
      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="slide-content">
              <i class="fas fa-store"></i>
              <span>Retail</span>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide-content">
              <i class="fas fa-industry"></i>
              <span>Manufacturing</span>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide-content">
              <i class="fas fa-utensils"></i>
              <span>Restaurant</span>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="slide-content">
              <i class="fas fa-box-open"></i>
              <span>Wholesale</span>
            </div>
          </div>
        </div>

        <div class="swiper-button-prev"><i class="fas fa-chevron-left"></i></div>
        <div class="swiper-button-next"><i class="fas fa-chevron-right"></i></div>
      </div>
    </div>


    <div class="waves-container">
    </div>

  </section>

  <!-- Feature Highlights -->
  <section class="feature-highlights">
    <h3>Our Services</h3>
    <div class="features-container">
      <div class="feature" *ngFor="let feature of features">
        <i class="{{feature.icon}}"></i>
        <h3>{{ feature.title }}</h3>
        <p>{{ feature.description }}</p>
        <div class="cta">
          <a class="cta-button" [routerLink]="[feature.link]">{{ feature.cta }}</a>
        </div>
      </div>
    </div>
  </section>



  <!-- Client Logos -->
  <section class="client-logos">
    <h2><span class="light-text">Trusted by</span> Leading Businesses</h2>
    <div class="client-logos-slider"
        (mouseenter)="pauseMarquee()"
        (mouseleave)="resumeMarquee()">
      <div class="logo-container">
        <div class="" *ngFor="let client of clients">
          <img [src]="client.logo" [alt]="client.name">
        </div>
      </div>
    </div>
  </section>


  <section class="e-invoicing-section">
    <div class="e-invoicing-wrapper">
      <div class="e-invoicing-content">
        <div class="e-invoicing-text">
          <!-- <canvas class="background"></canvas> -->
          <h2>What is E-Invoicing? <span class="light-text">Why is it essential?</span></h2>
          <p>E-Invoicing, or Electronic Invoicing, refers to the digital creation of invoices directly on the government’s GST portal. It includes the generation, validation, and exchange of invoices between suppliers and buyers in an integrated electronic format. The main goal is to automate and standardize the invoicing process, ensuring consistent and accurate reporting of taxable transactions.

            </p>
          <p>E-Invoicing streamlines B2B transactions by enabling real-time reporting, reducing errors, and ensuring ERP compatibility. It enhances compliance, curbs tax evasion, and reduces paperwork and administrative costs, offering long-term savings.

            </p>
          <p>E-Invoicing in India has made tax processes much simpler and helped businesses follow the rules better. The government rolled it out step by step, and now it’s mandatory for businesses with an annual turnover over ₹5 crore (as of 2023) to create e-invoices.</p>
        </div>
        <div class="e-invoicing-image">
          <img src="../../../../../assets/images/enquiry/E-invoicing.webp" alt="E-Invoicing Image">
        </div>
      </div>
    </div>
  </section>

   <!-- New section for infinite Swiper slideshow -->
   <section class="infinite-swiper-section">
    <h3>A-Square ERP Software <span class="light-text"> Functionalities </span></h3>
    <div class="swiper-container" id="infiniteSwiper2">
      <div class="swiper-wrapper" >
        <div class="swiper-slide">
          <div class="slide-content">
            <i class="fas fa-barcode"></i>
            <h3>Automated Serial Number</h3>
            <p class="marquee">A-Square ERP generates unique serial numbers and barcodes for tracking and managing each product efficiently.</p>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide-content">
            <i class="fas fa-money-bill"></i>
            <h3>Comprehensive GST Compliant</h3>
            <p class="marquee">With our ERP software, you can access GST R1, R2, and R3 reports for efficient tax management.</p>
          </div>
        </div>

        <div class="swiper-slide">
          <div class="slide-content">
            <i class="fas fa-file-import"></i>
            <h3>Realtime Tracking & Reporting</h3>
            <p class="marquee">A-Square ERP software provides real-time tracking and report generation to eliminate inaccuracies in data handling.</p>
          </div>
        </div>


        <div class="swiper-slide">
          <div class="slide-content">
            <i class="fas fa-sync-alt"></i>
            <h3>Automated Revenue Reconciliation</h3>
            <p class="marquee">Using our ERP software, you can automate revenue reconciliation by matching e-invoices, e-way bills, receipts, and sales records.</p>
          </div>
        </div>


      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-buttons-container">
        <div class="swiper-buttons">
          <div class="swiper-button-prev"><i class="fas fa-chevron-left"></i></div>
          <div class="swiper-button-next"><i class="fas fa-chevron-right"></i></div>
        </div>
      </div>
    </div>
  </section>

  <section class="eway-billing-section">
    <div class="eway-billing-wrapper">
      <div class="eway-billing-content">
        <div class="eway-billing-text">
          <!-- <canvas class="background"></canvas> -->
          <canvas id="tsparticles" class="background"></canvas>
          <h2>What is E-Way Billing, & <span class="light-text">Why is it needed?</span></h2>
          <p>An e-way bill is an electronic document generated on the GST portal for the transportation of goods. It is mandatory for any supplier or agent transporting goods with a consignment value exceeding Rs. 50,000 to create and present this e-way bill. The bill consists of two sections:

            </p>
          <p>Part A requests information such as the recipient's GSTIN, delivery location, invoice or challan number and date, HSN code, value of goods, transport document number (or Goods Receipt Number for road transport), and the reason for transportation.
            Part B includes transporter details, such as vehicle number and transporter ID, and is used to complete the e-way bill.

            </p>
          <p>A-Square ERP does E-Way billing seamlessly in an automated way. So you don't have to worry about manually checking billing data for the E-Way billing procedure.</p>
        </div>
        <div class="eway-billing-image">
          <img src="../../../../../assets/images/enquiry/E-wayBill.webp" alt="E-Way Billing Image">
        </div>
      </div>
    </div>
  </section>



  <!-- New section for infinite Swiper slideshow -->
<section class="infinite-swiper-section">
  <h3>A-Square ERP Software <span class="light-text">Benefits </span></h3>
  <div class="swiper-container" id="infiniteSwiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="slide-content">

          <i class="fas fa-lock"></i>
          <h3>Data Privacy & Security</h3>
          <p>A-Square ERP ensures robust data privacy by safeguarding your sensitive data with advanced security measures. It offers full assurance that your data remains secure & protected across the cyber domain.</p>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="slide-content">
          <i class="fas fa-headset"></i>
          <h3>Dedicated & Expert Support</h3>
          <p>With our ERP software, you get dedicated and expert support, ensuring seamless implementation, troubleshooting, and continuous assistance for your business needs.</p>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="slide-content">
          <i class="fas fa-layer-group"></i>
          <h3>Customizable Dashboard</h3>
          <p>With our ERP software, customize your dashboard to fit your workflow, enhancing efficiency and business productivity.</p>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="slide-content">
          <i class="fas fa-laptop-code"></i>
          <h3>Auto-validation of Data</h3>
          <p>A-Square ERP ensures automated data validation, eliminating the chance of errors. With built-in advanced validation processes, your data is checked and verified accurately without manual intervention.
          </p>
        </div>
      </div>

      <div class="swiper-slide">
        <div class="slide-content">
          <i class="fas fa-users"></i>
          <h3>Save Time & Manpower</h3>
          <p>With our ERP software, you can save time and manpower by automating processes, streamlining workflows, and improving efficiency across your business operations.</p>
        </div>
      </div>


    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-buttons-container">
      <div class="swiper-buttons">
        <div class="swiper-button-prev"><i class="fas fa-chevron-left"></i></div>
        <div class="swiper-button-next"><i class="fas fa-chevron-right"></i></div>
      </div>
    </div>
  </div>
</section>


<section class="two-column-section">
  <h2><span class="light-text">Our Integration</span> Partners</h2>
  <div class="two-column-grid">
    <div class="first-column">
      <!-- Content for the first column goes here -->
    </div>
    <div class="second-column">
      <!-- Swiper Vertical Slider -->
      <div class="integration-partners-grid">
        <img src="../../../../../assets/images/enquiry/integrationPartners/azure.png" alt="azure">
        <img src="../../../../../assets/images/enquiry/integrationPartners/gcp.png" alt="google cloud platform">
        <img src="../../../../../assets/images/enquiry/integrationPartners/razorpay.png" alt="razorpay">
        <img src="../../../../../assets/images/enquiry/integrationPartners/tally.png" alt="tally">
      </div>
    </div>
  </div>
</section>




  <!-- Our Integration Partners -->
  <section class="sectors">
    <h2><span class="light-text">Sectors We Work In</span> with A-Square ERP Software</h2>
    <p>Explore the diverse sectors where A-Square ERP Software excels in delivering tailored solutions. With industry-specific expertise, we empower businesses to streamline operations, enhance efficiency, and achieve sustainable growth across various domains.</p>
    <div class="sectors-grid">
      <div class="swiper sectors-slider" >
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let ImgSrc of sectorsimg">
            <div class="sector-img">
              <img [src]="ImgSrc.imgSrc" [alt]="ImgSrc.alt" [attr.label]="ImgSrc.alt">
              <caption>{{ ImgSrc.label }}</caption>
            </div>
          </div>
        </div>
        <div class="swiper-buttons-container">
          <div class="swiper-buttons">
            <div class="swiper-button-next">
              <i class="fas fa-chevron-right"></i>
            </div>
            <div class="swiper-button-prev">
              <i class="fas fa-chevron-left"></i>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>



  <section class="testimonials">
    <h2> <span class="light-text">What</span> Our Clients Say</h2>
    <div class="testimonial-slider swiper-container">
      <div class="swiper-wrapper">
        <div class="testimonial swiper-slide" *ngFor="let testimonial of testimonials">
          <div class="swiper-content">
            <p>"{{ testimonial.quote }}"</p>
            <div class="author">
              <!-- <img [src]="testimonial.avatar" [alt]="testimonial.name"> -->
              <div>
                <h4>{{ testimonial.name }}</h4>
                <p> {{ testimonial.company }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
      <!-- Add Navigation -->
      <div class="swiper-button-prev"><i class="fas fa-chevron-left"></i></div>
      <div class="swiper-button-next"><i class="fas fa-chevron-right"></i></div>
    </div>
  </section>

  <!-- FAQ Section -->
  <!-- <section class="faq">
    <h2>Frequently Asked Questions</h2>
    <div class="faq-list">
      <div class="faq-item" *ngFor="let item of faqItems">
        <h3 (click)="toggleFaq(item)">{{ item.question }}</h3>
        <p [@faqAnimation]="item.isOpen ? 'open' : 'closed'">{{ item.answer }}</p>
      </div>
    </div>
  </section> -->
</div>

<app-web-footer></app-web-footer>
