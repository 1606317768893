import { createReducer, on } from "@ngrx/store";
import { loadLedgersSuccess, loadLedgersFailure, addLedger, updateLedger, deleteLedger } from "./ledger.actions";

export interface LedgerState {
    ledgers: any[];
    error: string | null;
  }
  
  const initialState: LedgerState = {
    ledgers: [],
    error: null,
  };

  export const ledgerReducer = createReducer(
    initialState,
    on(loadLedgersSuccess, (state, { ledgers }) => ({
      ...state,
      ledgers,
      error: null
    })),
    on(loadLedgersFailure, (state, { error }) => ({
      ...state,
      error
    })),
    on(addLedger, (state, { ledger }) => ({
      ...state,
      ledgers: [...state.ledgers, ledger],
      error: null
    })),
    on(updateLedger, (state, { ledger }) => ({
      ...state,
      ledgers: state.ledgers.map(l =>
        l.ledgerId === ledger.ledgerId ? { ...l, ...ledger } : l
      ),
    })),
    on(deleteLedger, (state, { ledgerId }) => ({
      ...state,
      ledgers: state.ledgers.filter(l => l.ledgerId != ledgerId),
    }))
  );