import { Injectable } from '@angular/core';
import { SignalrService } from 'src/app/services/signalr.service';
import { Store } from '@ngrx/store';
import * as fromLedger from 'src/app/common/stores/ledger/ledger.selector';
import * as LedgerActions from 'src/app/common/stores/ledger/ledger.actions';
import * as fromItem from 'src/app/common/stores/item/item.selector';
import * as ItemActions from 'src/app/common/stores/item/item.actions';
import { forkJoin, map, of, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private store: Store) { }

  loadInitialData(companyID: number, financialYear: string) {
    forkJoin([
      of(this.getAllLedgers(companyID, financialYear)),
      of(this.getAllItems(companyID, financialYear)),
      of(this.getAllItemCompanyLedgers(companyID, financialYear))
    ])
  }

  getAllLedgers(companyID: number, financialYear: string) {
      this.store.select(fromLedger.selectAllLedgers(companyID, financialYear)).pipe(
        take(1),
        map(ledgers => {
          if (!ledgers || ledgers.length == 0) {
            this.store.dispatch(LedgerActions.loadLedgers());
          }
        })
      ).subscribe();
    }
  
    getAllItems(companyID: number, financialYear: string) {
      this.store.select(fromItem.selectAllItems(companyID, financialYear)).pipe(
        take(1),
        map(items => {
          if (!items || items.length == 0) {
            this.store.dispatch(ItemActions.loadItems());
          }
        })
      ).subscribe();
    }
  
    getAllItemCompanyLedgers(companyID: number, financialYear: string) {
      this.store.select(fromItem.selectAllItemCompanyLedgers(companyID, financialYear)).pipe(
        take(1),
        map(ledgers => {
          if (!ledgers || ledgers.length == 0) {
            this.store.dispatch(ItemActions.loadCompanyLedgers());
          }
        })
      ).subscribe();
    }
}
