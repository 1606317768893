import { Component, HostListener, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from '../interface/Company';
import { CookieService } from 'ngx-cookie-service';
import { LogService } from 'src/app/services/common/log.service';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import Swal from 'sweetalert2';
import { SubscriberDetails } from 'src/app/modules/admin/interface/SubscriberDetails';
import { SubscriberLoginResp } from '../interface/Subscriber/SubscriberLoginResp';
import { off } from 'hammerjs';
import { isPlatform, Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';

interface FinancialYear {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.css']
})
export class ViewCompanyComponent {
  subscriberID: string;
  constructor(private router: Router,
    private authService: AuthService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private cookieService:CookieService,
    private logService:LogService,
    private subscriberService:SubscriberService,
    private platform: Platform) { }

  dataSource = new MatTableDataSource<Company>();
  displayedColumns: string[] = ['srNo', 'Name', 'Code', 'actions'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  subscriberForm: FormGroup;
  deleteCompanyID: string = "";
  displayStyle = "none";
  allCompanies: any;

  financialyears: FinancialYear[] = [];
  formGroup: FormGroup;
  deleteForm: FormGroup;
  showFinancialYear = false;
  displaySubscriptionModal = "none";
  showModalClose = false;
  subscriptionDetailsFormGroup : FormGroup;
  isErrorMessage = 0;
  isPasswordMatchError = 0;

  hide = true;
  subscriberDetails: SubscriberDetails;

  subscriberResp: SubscriberLoginResp = null;

  searchGroup: FormGroup;

  async ngOnInit() {
    try {
      this.route.queryParamMap.subscribe(params => {
        if (params.has('subscriberID')) {
          this.subscriberID = params.get('subscriberID');
        }
      });

        this.GetAllCompanies();
        this.getSubscriberDetails();

      this.initSubscriptionDetailsForm();
    } catch (ex) {
      this.logService.InsertExceptionLog(ex, "View Company", "ngOnInit");
      console.log(ex);
    }

    this.InitForm();
  }

  private initSubscriptionDetailsForm() {
    this.subscriptionDetailsFormGroup = new FormGroup({
      SubscriberID: new FormControl(this.subscriberID),
      MobileNumber: new FormControl(),
      Address: new FormControl(),
      State: new FormControl(),
      City: new FormControl(),
      Pincode: new FormControl(),
      GstNo: new FormControl(),
      BusinessType: new FormControl('General'),
      Password: new FormControl(),
      ConfirmPassword: new FormControl()
    });
  }

  InitForm() {
    this.searchGroup = new FormGroup({
      search: new FormControl('')
    });

    this.deleteForm = new FormGroup({
      deleteCompanyFY: new FormControl(),
      financialYear: new FormControl()
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['subscriber-login']);
  }

  // For Paginator and Sorting
  ngNewpaginator() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  GetAllCompanies() {
    try {
      this.allCompanies = [];

      this.subscriberForm = new FormGroup({
        SubscriberID: new FormControl(this.subscriberID)
      });

      this.companyService.GetAllCompanies(this.subscriberForm.value).subscribe(
        (result) => {
          this.allCompanies = result;
          this.dataSource = new MatTableDataSource<Company>(result);
          this.ngNewpaginator();
        }, (err: Error) => {
          console.log(err.message);
        });
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex,"View Company","GetAllCompanies");
      console.log(ex);
    }
  }

  GetFinancialYear() {
    try {
      this.formGroup = new FormGroup({
        CompanyID: new FormControl(this.deleteCompanyID)
      });

      if (this.formGroup.valid) {
        this.authService.GetFinancialYear(this.formGroup.value).subscribe(
          (result) => {
            const years = result.financialYear.split(",");
            for (var i = 0; i < years.length; i++) {
              var year: FinancialYear = {
                value: years[i],
                viewValue: years[i].split("/")[2]
              };

              this.financialyears.push(year);
            }
          }, (err: Error) => {
            console.log(err.message);
          })
      }
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex,"View Company","GetAllCompanies");
      console.log(ex);
    }
  }

  getSubscriberDetails() {
    try {
      this.subscriberForm = new FormGroup({
        SubscriberID: new FormControl(this.subscriberID)
      });

      this.subscriberService.GetSubscriberDataForSubscriberID(this.subscriberForm.value).subscribe(
        (result: SubscriberDetails) => {
          this.subscriberDetails = result;
          this.subscriptionDetailsFormGroup.patchValue({
            MobileNumber: this.subscriberDetails.mobileNumber,
            Address:this.subscriberDetails.address,
            State: this.subscriberDetails.state,
            City: this.subscriberDetails.city,
            Pincode: this.subscriberDetails.pincode,
            GstNo: this.subscriberDetails.gstNo,
            BusinessType: this.subscriberDetails.businessType,
            Password: this.subscriberDetails.password,
            ConfirmPassword: this.subscriberDetails.password
          });

          let subscriber = JSON.parse(sessionStorage.getItem('subscriber'));
          var IsSubscriptionDetails = subscriber.isSubscriptionComplete;
          if (IsSubscriptionDetails != true) {
            this.showModalClose = false;
            this.displaySubscriptionModal = "block";
          } else {
            this.showModalClose = true;
          }
        }, (err: Error) => {
          console.log(err.message);
        });
    }
    catch (ex) {
      this.logService.InsertExceptionLog(ex,"View Company","getSubscriberDetails");
      console.log(ex);
    }
  }

  onChangeDeleteOption() {
    if (this.deleteForm.controls['deleteCompanyFY'].value === 'financialYear') {
      this.showFinancialYear = true;
    } else {
      this.showFinancialYear = false;
    }
  }

  openSubscriptionDetailsModal() {
    this.displaySubscriptionModal = "block";
  }

  closeSubscriptionDetailsModal() {
    this.displaySubscriptionModal = "none";
  }

  redirectToAddCompany() {
    this.router.navigate(
      ['add-company']);
  }

  redirectToPayment(){
    this.router.navigate(['payment'],{ queryParams: { subscriberID: this.subscriberID } });
  }

  closepopup() {
    this.displayStyle = "none";
  }

  getCompany(company) {
    this.deleteCompanyID = company.companyId;
    this.GetFinancialYear();

    this.displayStyle = "block";
  }

  loginCompany(company) {
    try {
      this.router.navigate(
        ['login'],
        { queryParams: { companyID: company.companyId, companyName: company.name } }
      );
    } catch (ex) {
      this.logService.InsertExceptionLog(ex,"View Company","loginCompany");
      console.log(ex);
    }
  }

  deleteCompany() {
    if (this.deleteForm.controls['deleteCompanyFY'].value === 'company') {
      try {
        this.router.navigate(
          ['login'],
          { queryParams: { deleteMode: 'company', companyID: this.deleteCompanyID } }
        );
      } catch (ex) {
        console.log(ex);
      }
    } else if (this.deleteForm.controls['deleteCompanyFY'].value === 'financialYear') {
      if (this.deleteForm.controls['financialYear'].value !== null) {
        let financialYear = this.deleteForm.controls['financialYear'].value;
        try {
          this.router.navigate(
            ['login'],
            { queryParams: { deleteMode: 'financialYear', companyID: this.deleteCompanyID, financialYear: financialYear } }
          );
        } catch (ex) {
          console.log(ex);
        }
      }
    }
  }

  UpdateSubscriberDetails(){
    try{
      this.isErrorMessage = 0;
      if(this.subscriptionDetailsFormGroup.value.MobileNumber == "" || this.subscriptionDetailsFormGroup.value.Address == "" || 
      this.subscriptionDetailsFormGroup.value.State == "" || this.subscriptionDetailsFormGroup.value.City == "" ||
      this.subscriptionDetailsFormGroup.value.Pincode == "" || this.subscriptionDetailsFormGroup.value.GstNo == "" || 
      this.subscriptionDetailsFormGroup.value.Password == "" || this.subscriptionDetailsFormGroup.value.ConfirmPassword == ""
      ){
        this.isErrorMessage = 1;
      } else if(this.subscriptionDetailsFormGroup.value.Password !== this.subscriptionDetailsFormGroup.value.ConfirmPassword) {
        this.isPasswordMatchError = 1;
      }
      else{
        if (this.subscriptionDetailsFormGroup.valid) {
          this.subscriberService.UpdateSubscriberDetails(this.subscriptionDetailsFormGroup.value).subscribe(
            (result) => {
              if(result.isSuccess == 1){
                sessionStorage.setItem("isSubscritionDetails","true");
                sessionStorage.setItem("businessType", this.subscriptionDetailsFormGroup.value.BusinessType);
                this.displaySubscriptionModal = "none";
                Swal.fire({
                  text: "Subscription Details Added Sucessfully.",
                  icon: "success"
                });
              }
            }, (err: Error) => {
              console.log(err.message);
            });
        }
      }      
    }
    catch(ex){
      console.log("Error in UpdateSubscriberDetails : " + ex);
    }
  }
}
